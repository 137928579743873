import React from 'react'

import Skillset from '../components/settings/skillset'

class Settings extends React.Component {

  render(){

    return(
      <span>
        <Skillset />
      </span>
    )
  }
}

export default Settings
